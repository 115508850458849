<template>
    <section>
      <top-alternative-section
        :image="topAlternative.image"
        :title="$t('prevencion.top.title')"
        :content="$t('prevencion.top.content')"
      ></top-alternative-section>

      <features-section
        :title="$t('prevencion.featuresSection.title')"
        :description="$t('prevencion.featuresSection.description')"
        :items="$t('prevencion.featuresSection.items')"
      ></features-section>
  
      <offers-section
        :items="$t('prevencion.offersSection.items')"
      ></offers-section>
  
      <contact-section></contact-section>
    </section>
  </template>
  
  <script>
  import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
  import PrevencionImage from "../../assets/prevencion.jpg";
  //import DescriptionSection from "../sections/DescriptionSection.vue";
  import OffersSection from "../sections/OffersSection.vue";
  import FeaturesSection from "../sections/FeaturesSection.vue";
  import ContactSection from "../sections/ContactSection.vue";
  
  export default {
    components: {
      TopAlternativeSection,
      //DescriptionSection,
      OffersSection,
      FeaturesSection,
      ContactSection
    },
    data() {
      return {
        PrevencionImage,
        topAlternative: {
          image: PrevencionImage,
          title: "Prevención y Monitoreo",
          content:
            "Orquestación y monitoreo de extremo a extremo para incrementar la efectividad en la prevención de fraudes emisor y adquirente"
        },
        description: {
          title: "La solucion",
          content:
            "Los Emisores, hoy se enfrentan a una serie de obstáculos importantes, incluida la necesidad de recorrer un camino a través de nuevos canales de pago, y gestionar operaciones de pago con múltiples procesadores, múltiples productos y en distintas monedas. Todo esto, al tiempo que ofrece un servicio al cliente superior en un entorno altamente competitivo y cada vez más regulado"
        },
        features: {
          title: "Beneficios",
          description: "Description",
          items: [
            {
              title: "Configuración",
              icon: "mdi-clock-outline",
              text:
                "Entorno efectivo para configurar múltiples reglas que permiten definir umbrales de alertamiento por Cliente y Afiliado."
            },
            {
              title: "Monitoreo",
              icon: "mdi-head-lightbulb",
              text:
                "Monitoreo de puntos de consumo y puntos de fraude por múltiples medios."
            },
            {
              icon: "mdi-cash-plus",
              title: "Orquestación",
              text:
                "Flexibilidad en la orquestación de distintas herramientas para análisis personalizado y con múltiples fuentes (enrolamiento, autorización, conciliación, compensación y liquidación)"
            },
            {
              title: "Listas Restrictivas",
              icon: "mdi-check-circle-outline",
              text:
                "Generación de Listas Restrictivas (Blacklist) y solicitudes de bloqueo.",
                
            },
            {
              title: "Geolocalización",
              icon: "mdi-bell-alert-outline",
              text:
                "Geolocalización de puntos de venta."
            }
          ]
        },
        offers: {
          items: [
          {
            icon: 'mdi-ticket-outline',
            title: 'Supervisión',
            text:
              'Módulos analíticos que detectan transacciones inusuales.'
          },
          {
            icon: 'mdi-text-account',
            title: 'Aclaraciones',
            text: 'Procesos de notificación a clientes y afiliados para seguimiento inmediato.'
          },
          {
            icon: 'mdi-puzzle-outline',
            title: 'Microservicios',
            text:
              'Capacidades de extensión de Microservicios a otros sistemas.'
          },
          {
            icon: 'mdi-cog-outline',
            title: 'Configiración',
            text:
              'Configuración rápida y fácil de implementar.'
          },
          {
            icon: 'mdi-laptop',
            title: 'Independecia',
            text:
              'Independencia de equipos de TI.'
          },
          {
            icon: 'mdi-web',
            title: 'Interfaz intuitiva',
            text:
              'Interfaz intuitiva para usuarios finales.' 
          }
        ]
        }
      };
    }
  };
  </script>
  